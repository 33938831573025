@import "../helpers/variables";
@import "../helpers/mixins";
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");

.a-di-non-vind {
  width: 783px !important;
}

.modal-application {
  padding: 48px 56px;
  font-family: "Rubik", sans-serif;

  @include media($md) {
    padding: 16px;
  }

  &__title {
    margin-bottom: 11px;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.4;
    color: #252525;

    @include media($md) {
      font-size: 24px;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media($md) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 24px;
    line-height: 1.4;
    color: #252525;

    @include media($md) {
      margin-bottom: 8px;
    }
  }

  &__calculator {
    display: flex;

    @include media($md) {
      margin-bottom: 16px;
    }

    input {
      width: 77px;
      height: 58px;
      border: 1px solid #aaa;
      border-radius: 4px;
      font-weight: 400;
      font-size: 24px;
      color: #181818;
      background-color: #fff;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    font-weight: 400;
    font-size: 30px;
    line-height: 1.4;
    color: #000;
    background-image: linear-gradient(87.61deg, #ffde00 0%, #ff8934 100%);
    background-size: 400% 100%;
    transition: background-size 0.3s;

    &:hover {
      box-shadow: 0 4px 10px rgba(148, 124, 4, 0.32);
      background-image: linear-gradient(87.61deg, #ffde00 0%, #ff8934 100%);
      background-size: 100% 100%;
    }

    &--minus {
      margin-right: 16px;
    }

    &--plus {
      margin-left: 16px;
    }
  }

  &__descr {
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
    color: #76767a;
  }

  &__next {
    display: inline-block;
    padding: 18px 40px;
    height: auto;
    border: none;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 0.01em;
    color: #252525;
    background-image: linear-gradient(87.61deg, #ffde00 0%, #ff8934 100%);
    background-size: 400% 100%;
    transition: background-size 0.3s;
    cursor: pointer;

    &:hover {
      box-shadow: 0 4px 10px rgba(148, 124, 4, 0.32);
      background-image: linear-gradient(87.61deg, #ffde00 0%, #ff8934 100%);
      background-size: 100% 100%;
    }

    &:active {
      box-shadow: inset 0 0 4px #947c04;
    }
  }
}